import React from 'react'
import '../styles/boton.scss'
import { Link } from 'gatsby';

export default function Boton(props) {

    let color = props.color;
    let url = props.url;
    let texto = props.texto;
    let externo = props.externo;

    return (
        <>
            {

                !externo ?
                    <Link className={`boton ${color}`} to={url}>{texto}</Link>
                    :
                    <a className={`boton ${color}`} href={url} target="_blank" rel="noopener noreferrer">{texto}</a>

            }
        </>
    )
}